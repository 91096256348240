/* eslint-disable no-magic-numbers */
// 注册
import React, { useEffect, useState } from 'react'
import styles from './register.module.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import activityApi from '@/apis/activity'
import indexApi from '@/apis'
import fingerprintjs from '@fingerprintjs/fingerprintjs'
import Skin from './components/skin/skin'
import { Storage, formatTime } from '@bihu/common-js'
import { CURRENT_SKIN_INDEX, ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import useFirstOrAfter from '@/hooks/use-first-or-after'
// import DetainmentPopup from './components/detainment-popup/detainment-popup'
import LoginPopup from './components/login-popup/login-popup'
interface Props {
  type?: 'getQuota', // 领取额度
}

const adChannelType = {
  register: 1,
  getQuota: 2
}

const Register: React.FC<Props> = props => {
  const { type } = props

  const [showPage, setShowPage] = useState<boolean>(false)
  const [codeDetail, setCodeDetail] = useState<any>()

  useFirstOrAfter(() => {
    const code:any = search.get('adChannelCode')
    if (code) {
      setAdChannelCode(code)
      localStorage.setItem('ad_channel_code', code)
      localStorage.setItem('hostName', '未来钱包')
    }
  }, () => {
  // eslint-disable-next-line no-console
    console.log('afterFn')
  }, [])

  const [search] = useSearchParams()

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  const judgeLoginAfterPage = () => {
    if (type === 'getQuota') {
      window.location.href = `/fill-information?adChannelCode=${adChannelCode}` //如果访问链接是getQuota类型，就跳转表单填写页
    } else {
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({ isNotFormReq: true }))}`
    }
  }

  // 有token状态下，判断下一页的跳转
  const judgeNextPage = async() => {
    // 如果是注册-下载模式，直接跳转下载页
    if (codeDetail.linkType === adChannelType.register) {
      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({
        isNotFormReq: true,
        isSpecialListCity: true
      }))}`
      return
    }
    // 获取表单填写状态  submitStatus >>> 0:未填写 1:已填写未提交 2: 已授权
    const res:any = await indexApi.getApplyStatus()
    if (res.code === 200) {
      if (res.data && res.data?.submitStatus === 1) {
        // 获取上回提交信息
        const submitResult:any = await indexApi.getFomrSubmitStatus({ adChannelCode })
        if (submitResult.productName === null) {
          window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify({
            isNotFormReq: true,
            rePageShowType: 0,
            revisit: true
          }))}`)
          return
        }

        if (submitResult.thirdpartyType === 5) {
          window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(submitResult))}&adChannelCode=${adChannelCode}&applyLimit=${res.data.applyLimit}`)
          return
        }

        // 跳转授权页
        let tempOptions = {
          adChannelCode,
          applyLimit: res.data.applyLimit,
          workCity: res.data.workCity,
          rqResult: submitResult
        }
        window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}&applyLimit=${res.data.applyLimit}`)
      } else if (res.data && res.data?.submitStatus === 10) {
        // rematchResult: 1-不需要重新匹配 2-重新匹配不到结果 3-重新匹配到结果
        const reMatchResult:any = await indexApi.getFomrSubmitStatus({ adChannelCode })
        let tempOptions:any = {}
        // 如果重现匹配并且有结果，跳至动图页
        if (reMatchResult.rematchResult === 3) {
          // 携带授权数据跳转重新匹配结果页
          tempOptions = {
            adChannelCode,
            applyLimit: res.data.applyLimit,
            workCity: res.data.workCity,
            rqResult: reMatchResult
          }
          window.location.replace(`/fill-information/rematch-waiting?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
          return
        }

        // 否则取上回的结果，跳至下载页
        tempOptions = JSON.parse(JSON.stringify(reMatchResult))
        tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
        tempOptions.rePageShowType = 0
        tempOptions.revisit = true // 标记为再访
        window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
      } else {
        // 来到这说明未提交表单
        judgeLoginAfterPage()
      }
    } else {
      setShowPage(true)
    }
  }

  const handleMainFlow = () => {
    // 获取平台来源
    const platFormSource = search.get('platformSource')
    if (platFormSource) {
      sessionStorage.setItem('FLATFORM_SOURCE', platFormSource)
    }

    const hasToken = Storage.get(TOKEN)
    if (hasToken && !codeDetail.location) { // 有token并且不需要获取定位，接着执行下一步
      judgeNextPage()
    } else {
      setShowPage(true)
    }
    console.log(adChannelCode)
  }

  useEffect(() => {
    if (adChannelCode) {
      // 获取到浏览器指纹后调用上报接口
      fingerprintjs.load().then(fp => fp.get())
        .then(result => {
          const { visitorId } = result

          activityApi.reportAdChannelClick({
            adChannelCode,
            fingerprint: visitorId,
            abTestType: 1
          })
        })
    }
  }, [adChannelCode])

  useEffect(() => {
    const getChannelDetail = async() => {
      const codeDetailRes:any = await activityApi.getChannelCodeDetail(adChannelCode)
      //广告渠道状态，1-正常，0-禁用
      if (codeDetailRes && codeDetailRes.channelStatus === 0) {
        window.location.href = '/failure'
        return
      }
      setCodeDetail(codeDetailRes)
    }
    if (adChannelCode) {
      getChannelDetail()
    }
  }, [adChannelCode])

  useEffect(() => {
    if (codeDetail) {
      // 执行页面主流程
      handleMainFlow()
    }
  }, [codeDetail])

  return (
    <div className={styles.registerPage}>
      {
        showPage ? <>
          <Skin type={type} channelDetail={codeDetail} callBack={() => judgeNextPage()} />
        </> : <>
          <div id="root">
            <div id="loading-box">
              <div className={styles.ballClipRotateMultiple}>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default Register
