/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './submit-waiting.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { ENTRANCE_TYPE } from '@/constants/common'
import { isIOS, isInWeChat, isMobile, download } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import indexApi from '@/apis'

interface Props{}

const WaitingSuccess:React.FC<Props> = () => {
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const options:any = search.get('options')
  const resData = JSON.parse(decodeURIComponent(options))

  const [canSubmitForm, setCanSubmitForm] = useState<boolean>(false)
  const [formResult, setFormResult] = useState<any>()

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const submitFormAfter = (res:any) => {
    // 需要跳转授权页
    if (res && res.formProcessStatus === 0 && res.productName) {
      if (res.thirdpartyType === 5) {
        window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(res))}&adChannelCode=${resData.adChannelCode}&isHuaWeiPhone=${resData.isHuaWeiPhone}&applyLimit=${resData.applyLimit}`)
        return
      }
      // 跳转授权页
      let tempOptions = {
        adChannelCode: resData.adChannelCode,
        isHuaWeiPhone: resData.isHuaWeiPhone,
        applyLimit: resData.applyLimit,
        workCity: resData.workCity,
        rqResult: res
      }
      window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
    } else {
      const query:any = res && res.productName ? res : { rePageShowType: resData.adChannelCode === '9o0w2B' && resData.isHuaWeiPhone ? 2 : 0 }
      window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(query))}`)
    }
  }

  // 倒计时
  useEffect(() => {
    const sourceType = search.get('sourceType')
    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      } else if (sourceType === 'submit') { //如果当前页面需要提交表单
        // 表单返回结果，执行下一步
        if (formResult) {
          submitFormAfter(formResult)
        }
      } else {
        // 非提交表单跳转(普通跳转)
        window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(resData))}`)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime, formResult])

  // 触发提交表单操作
  useEffect(() => {
    const toSubmitForm = async(params:any) => {
      try {
        let res:any = await indexApi.saveCustomerInfo(params)
        // 报错表单提交结果
        setFormResult(res)
      } catch (error) {
        setTimeout(() => {
          navigate(-1)
        }, 3000)
      }
    }
    const sourceType = search.get('sourceType')
    if (sourceType === 'submit') {
      setCanSubmitForm(true) // 将canSubmitForm设置为true
      if (canSubmitForm) { // 这里用canSubmitForm，是不让submit重复提交
        toSubmitForm(resData)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSubmitForm])

  return (
    <section className={styles.authorizePage}>
      <Header title="未来钱包" />
      {/* <img className={styles.progress2} src={require('@imgs/gif/progress-n.gif')} /> */}

      <div className={styles.headerBox}>
        <img className={styles.progress1} src={require('@imgs/gif/progress-1.gif')} />
      </div>
      <div className={styles.waitingText1}>您的信息正在认证中...</div>
      <div className={styles.waitingText2}>根据您的资料未来钱包为您重新评估额度</div>
      <div className={styles.progressBar}>
        <img className={styles.progress2} src={require('@imgs/gif/progress-n.gif')} />
      </div>
    </section>
  )
}

export default WaitingSuccess