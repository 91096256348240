/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styles from './success.module.scss'
import { isIOS, isInWeChat, isMobile, download, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import { useSearchParams } from 'react-router-dom'
import { Toast } from 'antd-mobile'
import copy from 'copy-to-clipboard'
import IosSkin from './components/ios-skin/ios-skin'
import ResultSkin from './components/result-skin/result-skin'
import WhiteListCity from './components/360-white-list-city/360-white-list-city'
import MiniGrogram from './components/mini-program/mini-program'
import NormalSkin from './components/normal-skin/normal-skin'

interface Props{}

const Success:React.FC<Props> = () => {
  const [search] = useSearchParams()
  const options:any = search.get('options')
  let resData:any = {}
  if (options) {
    try {
      resData = JSON.parse(decodeURIComponent(options))
    } catch {
      resData = JSON.parse(options)
    }
  }
  const { productName } = resData
  const { thirdpartyApiType } = resData
  const { thirdpartyType } = resData
  const { thirdpartyTargetUrl } = resData
  const { isNotFormReq } = resData
  let { applyStatus } = resData //是否hold单，状态为8代表hold单
  const { isSpecialListCity } = resData // 是否来自特殊城市的用户 || 注册-下载模式的用户

  /** rePageShowType 0/null 不处理 1：取消下载需求 2：引导公众号需求 */
  let { rePageShowType } = resData

  const [isMiniProgram, setIsMiniProgram] = useState(false)
  // 渠道详情
  const [codeDetail, setCodeDetail] = useState<any>()
  //isPageSetup 为true时 结果页无只有分发结果，无H5产品和下载
  const [isPageSetup, setIsPageSetup] = useState(false)

  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  useEffect(() => {
    const getChannelDetail = async() => {
      const codeDetailRes:any = await activityApi.getChannelCodeDetail(adChannelCode)
      setCodeDetail(codeDetailRes)
      if (codeDetailRes.pageSetup) {
        setIsPageSetup(true)
      }
    }
    if (adChannelCode) {
      getChannelDetail()
    }
  }, [adChannelCode])

  useEffect(() => {
    // 获取平台来源
    const platFormSource = sessionStorage.getItem('FLATFORM_SOURCE')
    if (platFormSource === 'MiniProgram') {
      setIsMiniProgram(true)
    }

    // 获取最新一次存储的渠道码
    const lastAdChannelCode = localStorage.getItem('ad_channel_code')
    if (lastAdChannelCode) {
      setAdChannelCode(lastAdChannelCode)
    }
  }, [])

  useEffect(() => {
    if (thirdpartyType === 4 && thirdpartyTargetUrl) {
      jumpOtherUrl()
    }
  }, [])

  // 跳转第三方
  const jumpOtherUrl = () => {
    if (thirdpartyType === 4 && thirdpartyTargetUrl) {
      // 跳转第三方链接
      window.location.href = thirdpartyTargetUrl
    }
  }

  const copyDownLoadUrl = () => {
    //
  }

  const miniComponent = () => {
    return (
      <MiniGrogram productName={productName} applyStatus={applyStatus} onChange={copyDownLoadUrl}></MiniGrogram>
    )
  }

  const otherResultComponent = () => {
    return (
      <div>
        <ResultSkin productName={productName} applyStatus={applyStatus} ></ResultSkin>
      </div>
    )
  }

  return (
    <div>
      { isMiniProgram && rePageShowType !== 1 ? miniComponent() : codeDetail && otherResultComponent() }
    </div>
  )
}

declare const window: Window & { WebViewJavascriptBridge: any, WVJBCallbacks: any }

export default Success