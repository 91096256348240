/* eslint-disable no-magic-numbers */
// API转H5产品皮肤
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './api-to-h5-skin.module.scss'
import {
  applyCreditOptionsMap
} from '@/pages/fill-information/options'
interface Props {
  rqResult: any, //授权信息详情
  agreementContent: string, //未来钱包信息授权协议内容
  youqianlaiProtocolName: '' //未来钱包信息授权协议名称
  isSpecialChannel: boolean, //是否需要特殊处理的渠道
}

const IosSkin: React.FC<Props> = props => {
  const { rqResult, agreementContent, youqianlaiProtocolName, isSpecialChannel } = props

  return (
    <div className={styles.apiToH5Skin}>
      <div className={styles.apiToH5Skin_header}>
        <img className={styles.apiToH5Skin_result_icon} src={require('@imgs/icons/pass-icon.png')} />
        <div className={styles.apiToH5Skin_result_textBox}>
          <div className={styles.apiToH5Skin_result_text1}>恭喜您的预审已通过</div>
          <div className={styles.apiToH5Skin_result_text2}>根据您的资质信息，成功为您匹配到资方</div>
        </div>
      </div>
      <div className={styles.apiToH5Skin_body}>
        <div className={styles.cardBox}>
          <div className={styles.cardBoxHeader}>
            <img className={styles.cardBoxHeaderBg} src={require('@imgs/information/title-bg.png')} />
            <div className={styles.cardBoxHeaderLeft}>本次贷款服务提供方</div>
            <div className={styles.cardBoxHeaderRight}>
              <img className={styles.cardBoxHeaderRightIcon} src={require('@imgs/icons/crooked-arrows.png')} />
              <div className={styles.cardBoxHeaderRightText}>今日申请人数{ rqResult && (1001 + rqResult?.todayMatchNum) }人</div>
            </div>
          </div>
          <div className={styles.cardBoxContent}>
            <div className={styles.companyBox}>
              <img className={styles.companyBoxLogo} src={require('@imgs/icons/default-company-logo.png')} />
              <div className={styles.companyBoxRight}>
                <div className={styles.companyBoxName}>
                  {
                    isSpecialChannel ? <>
                      <span className={styles.productNmae}>{ rqResult && rqResult?.productName }</span>
                    </> : <>
                      <span>{ rqResult && rqResult?.productCompany }</span>
                    </>
                  }
                </div>
                <div className={styles.companyBoxOther}>
                  <div className={styles.companyBoxOtherItem}>
                    <div className={styles.companyBoxOtherItemValue}>20<span className={styles.unit}>万</span></div>
                    <div className={styles.companyBoxOtherItemLabel}>最高额度(元)</div>
                  </div>
                  <div className={styles.companyBoxOtherItem}>
                    <div className={styles.companyBoxOtherItemValue}>36<span className={styles.unit}>期</span></div>
                    <div className={styles.companyBoxOtherItemLabel}>最高期数</div>
                  </div>
                  <div className={styles.companyBoxOtherItem}>
                    <div className={styles.companyBoxOtherItemValue}>96%</div>
                    <div className={styles.companyBoxOtherItemLabel}>今日放款率</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.otherInfoBox}>
              <div className={styles.otherInfoItem}>
                <div className={styles.ohterInfoItemLabel}>需求金额：</div>
                <div className={styles.ohterInfoItemValue}>{ applyCreditOptionsMap[rqResult.applyLimit] }</div>
              </div>
              <div className={styles.otherInfoItem}>
                <div className={styles.ohterInfoItemLabel}>所在城市：</div>
                <div className={styles.ohterInfoItemValue}>{ rqResult.workCity }</div>
              </div>
              <div className={styles.otherInfoItem}>
                <div className={styles.ohterInfoItemLabel}>{isSpecialChannel ? '受理公司：' : '受理产品：'}</div>
                <div className={styles.ohterInfoItemValue}>
                  {
                    isSpecialChannel ? <>
                      <span>{ rqResult && rqResult?.productCompany }</span>
                    </> : <>
                      <span>{ rqResult && rqResult?.productName }</span>
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.protocolBox}>
          <div className={styles.protocolBoxContent}>
            <div className={styles.protocolBoxTitle}>{ youqianlaiProtocolName }</div>
            <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
          </div>
        </div>
        <div className={styles.specialTips}>
          <div className={styles.specialTipsTag}>特别提示</div>
          <span className={styles.specialTipsContent}>我们会根据您的资质，为您匹配专业的贷款咨询服务机构，请您查阅《{youqianlaiProtocolName}》《机构相关协议》。</span>
        </div>
      </div>
    </div>
  )
}

export default IosSkin
