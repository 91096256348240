/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react'
import Header from '@/components/header/header'
import styles from './info-audit-agreement.module.scss'
import classNames from 'classnames'
import { download, downloadFile, getParams, formatTime, Storage } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from 'antd-mobile'
import indexApi from '@/apis'
import { TOKEN } from '@/constants/storage'

interface Props{}
// 个人信息授权书
const RegisterAgreement:React.FC<Props> = props => {
  const hostNameObj = getHostNameObj()

  const [isShowHeader, setIsShowHeader] = useState(false)
  const [name] = useState(hostNameObj.appName)
  const [search] = useSearchParams()
  const [agreementId, setAgreementId] = useState('')
  const [agreementContent, setAgreementContent] = useState('')
  const [isAgreementLogInfo, setIsAgreementLogInfo] = useState(false)
  const [userName, setUserName] = useState('')
  const [userAccount, setAccount] = useState('')
  const [signDate, setSignDate] = useState('')
  const [uid, setUid] = useState('')
  const [isShowDowoloadBtn, setIsShowDowoloadBtn] = useState(true)
  const [thirdpartyApiType, setThirdpartyApiType] = useState('')
  const [agreementTitle, setAgreementTitle] = useState('')
  const [userSignProtocolLogs, setUserSignProtocolLogs] = useState([])
  const [token, setToken] = useState('')
  const [signFieldFlag, setSignFieldFlag] = useState(false)

  let isFirstRun = true

  useEffect(() => {
    const title:any = search.get('title')
    setAgreementTitle(title)
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }

    let tokenTemp = Storage.get(TOKEN)
    const urlToken = search.get('token')
    if (urlToken) {
      Storage.set(TOKEN, urlToken)
      setToken(urlToken)
    } else if (tokenTemp) {
      setToken(tokenTemp)
    }
  }, [])

  useEffect(() => {
    if (!token) {
      return
    }
    if (!isFirstRun) {
      return
    }
    isFirstRun = false
    // 获取协议记录ID(给工作人员查看协议时使用)
    const userSignProtocolLogIds:any = search.get('userSignProtocolLogIds')
    const thirdpartyApiTypeCode:any = search.get('thirdpartyApiType')
    setThirdpartyApiType(thirdpartyApiTypeCode)
    const ids = JSON.parse(decodeURIComponent(userSignProtocolLogIds))
    // // 如果拿到协议记录ID
    if (userSignProtocolLogIds) {
      setIsAgreementLogInfo(true)
      getUserSignProtocolLog(`ids=${ids}`)
      return
    }

    const id:any = search.get('agreementId')
    const productId:any = search.get('productId')
    async function getAgreementInfo() {
      const res:any = await indexApi.getAgreementInfo(id, productId)
      if (res.signFieldFlag === 1) {
        setSignFieldFlag(true)
      }
      setAgreementContent(res.content)
    }
    getAgreementInfo()
  }, [token])

  const getUserSignProtocolLog = async(userSignProtocolLogIds:any) => {
    const res:any = await indexApi.getAgreementLogInfo(userSignProtocolLogIds)
    setUserSignProtocolLogs(res)
    // setAgreementContent(res.content)
    setUserName(res.realName)
    setAccount(res.mobile)
    setSignDate(res.signAt)
    setUid(res.uid)
  }

  return (
    <div className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title={ agreementTitle } />
      }
      {
        <div>
          {
            isAgreementLogInfo ? <>
              {
                userSignProtocolLogs.map((item:any) => {
                  return <div key={item.id} style={{ marginBottom: '30px' }}>
                    <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                    {/* 非填充，兼容非填充已签署协议的签署数据回显 */}
                    {
                      item.signFieldFlag !== 1 && <div>
                        {
                          // 504是比融的代号
                          thirdpartyApiType === '504' ? <>
                            <p>您的登录账号：【{ item.mobile }】</p>
                            <p>您的UID：【{ item.uid }】</p>
                          </> : <>
                            <p>用户姓名：【{ item.userName }】</p>
                            <p>用户账号：【{ item.userAccount }】</p>
                            <p>签署日期：【{ formatTime(item.signAt, 'YYYY-MM-DD') }】</p>
                          </>
                        }
                      </div>
                    }
                  </div>
                })
              }
            </> : <>
              <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
            </>
          }
        </div>
      }
    </div>
  )
}

export default RegisterAgreement