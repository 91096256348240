/* eslint-disable no-magic-numbers */
import activityApi from '@/apis/activity'
import productApi from '@/apis/product'
import { EntranceType } from '@/types/apis/index'
import Event from '@/event'
import { APPLY_STATUS_MODAL } from '@/event/constant'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Tree = Record<string, any>

interface Options<T> {

  /** 子节点数组属性名 */
  childrenProp?: string

  /** 父节点 */
  parent?: T | null;
}

// 递归树结构的数据，并对每一个节点执行 callback
export const traverseTree = <T extends Tree>(tree: T[], callback: (treeItem: T, parentItem: T | null) => void, {
  parent = null,
  childrenProp = 'children'
}: Options<T> = {}) => {
  tree.forEach(treeItem => {
    callback(treeItem, parent)
    const children = treeItem[childrenProp] as T[]
    if (children && children.length) {
      traverseTree(children, callback, {
        parent: treeItem,
        childrenProp
      })
    }
  })
}

// 跳转到内嵌页面
export const jumpInlinePage = async(productId: number, entranceType:EntranceType | undefined, productName?: string) => {
  const res = await productApi.apply({
    productId, //产品id
    entranceType, // 入口类型，1-首页中插，2-首页弹窗、3-开屏、4-顶部推荐、5-首页列表、6-产品列表 7-随机推荐
    appH5: 4 //未来钱包
  })

  window.location.href = `/inline-page?url=${encodeURIComponent(JSON.stringify(res.thirdpartyTargetUrl))}${productName ? `&productName=${productName}` : ''}`
}

// 上报产品曝光
export const reportProductExposure = (productId: number, entranceType:EntranceType, appH5?:number) => {
  activityApi.reportProductExposure({
    productId,
    entranceType,
    appH5,
  })
}
// 生成随机数范围
export const randomNum = (minNum:number, maxNum:number) => {
  return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
}

/**
 * 通过身份证号码查出生日期
 * @param {string} idCard 身份证号码
 * @returns {string} birthday
 */
export function getBirthday(idCard:string):string {
  let birthday = ''
  if (idCard != null && idCard !== '') {
    if (idCard.length === 15) {
      birthday = `19${idCard.substring(6, 12)}`
    } else if (idCard.length === 18) {
      birthday = idCard.substring(6, 14)
    }

    birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-')
  }

  return birthday
}

/**
 * 通过身份证判断性别
 * @param {string} idCard 身份证号码
 * @returns {string} birthday
 */
export function getSex(idCard: string):string {
  let sexStr = ''
  if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
    sexStr = '1'
  } else {
    sexStr = '2'
  }
  return sexStr
}

/**
 * 通过身份证号计算年龄
 * @param {string} idCard 身份证号码
 * @returns {number} birthday
 */
export function getAge(idCard:string):number {
  let len = (`${idCard}`).length
  if (len === 0) {
    return 0
  } else if (len !== 15 && len !== 18) {
    //身份证号码只能为15位或18位其它不合法
    return 0
  }
  let strBirthday = ''
  if (len === 18) {
    //处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday
      = `${idCard.substring(6, 10)
      }/${
        idCard.substring(10, 12)
      }/${
        idCard.substring(12, 14)}`
  }
  if (len === 15) {
    strBirthday
      = `19${
        idCard.substring(6, 8)
      }/${
        idCard.substring(8, 10)
      }/${
        idCard.substring(10, 12)}`
  }
  //时间字符串里，必须是“/”
  let birthDate = new Date(strBirthday)
  let nowDateTime = new Date()
  let age = nowDateTime.getFullYear() - birthDate.getFullYear()
  //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth()
    || (nowDateTime.getMonth() === birthDate.getMonth()
      && nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}