/* eslint-disable no-magic-numbers */
// 结果页皮肤
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './result-skin.module.scss'
import classNames from 'classnames'
import { Toast, Button, Swiper } from 'antd-mobile'
import { parseNum, isPhone, Storage } from '@bihu/common-js'
import BigNumber from 'bignumber.js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { TOKEN, ENTER_AGREEMENT, CURRENT_SKIN_INDEX } from '@/constants/storage'
import activityApi from '@/apis/activity'
import { useSearchParams } from 'react-router-dom'
import indexApi from '@/apis'
import { SwiperRef } from 'antd-mobile/es/components/swiper'
interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
}

const IosSkin: React.FC<Props> = props => {
  const { productName, applyStatus } = props

  return (
    <div className={styles.resultSkin}>
      <div className={styles.main}>
        <div className={styles.logoBox}>
          <img className={styles.logoImg} src={require('@imgs/ios-skin/logo.png')} />
          {/* <img className={styles.logo} src={require('@imgs/ylogo.png')} alt="logo" /> */}
          <div className={styles.logoBoxRight}>
            {/* {
              localStorage.getItem('hostName') === '未来钱包' ? <>
                <img className={styles.logoText} src={require('@imgs/youqianlai-text.png')} alt="youqianlai-text" />
              </> : <>
                <img className={styles.youqianLogoText} src={require('@imgs/youqian-text.png')} alt="youqian-text" />
              </>
            }
            <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" /> */}
          </div>
        </div>
        {
          productName ? <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              {
                applyStatus === 8 ? <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{productName}</span>匹配成功！</div>
                    <div className={styles.applyResultBoxText2}>审核人员将在<span className={styles.highlight}>工作时间来电</span>完成审核</div>
                  </div>
                </> : <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{productName}</span>申请成功！</div>
                    <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
                  </div>
                </>
              }
            </div>
            <div className={classNames(styles.containers, styles.passBox)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>请留意审核人员来电</div>
              </div>
            </div>
          </> : <>
            <div className={classNames(styles.containers, styles.failBox)}>
              <img className={styles.noPass} src={require('@imgs/form-result/no-pass.png')} alt="no-pass" />
              <div className={styles.failBoxText1}>预审未通过</div>
              <div className={styles.failBoxText2}>抱歉，您的预审未通过。</div>
              <div className={styles.failBoxText3}>请点击左上角进行退出</div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default IosSkin
